import * as React from 'react';
import { cn } from 'lib/utils';
import { Label } from '@components/ui/label';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  noBorder?: boolean; // Add this line
  baseClassName?: string;
  multipleFiles?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, noBorder = false, baseClassName, multipleFiles, ...props }, ref) => {
    return (
      <div
        className={cn(
          'rounded-md py-3 px-4 group relative space-y-1',
          !noBorder && 'ring-1 ring-inset ring-border focus-within:ring-2 focus-within:ring-accent', // Update this line,
          baseClassName
        )}
      >
        {label && (
          <Label htmlFor={props.id} className="group-focus-within:text-accent">
            {label}
          </Label>
        )}
        <input
          type={type}
          ref={ref}
          {...props}
          className={cn(
            'block w-full border-0 p-0 text-foreground placeholder:text-muted-foreground focus:ring-0 focus:border-0 bg-transparent outline-none',
            className,
            noBorder && 'ring-0 border-0' // Optionally, add this line
          )}
          multiple={multipleFiles}
        />
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
